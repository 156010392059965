import React from 'react';
import { Route, Routes, Link } from 'react-router-dom';
import Home from './pages/Home/Home';
import Resume from './pages/Resume/Resume';
import './App.css';


const App: React.FC = () => {
  return (
    <div className="App">
      <header className="App-header">
        <div className="logo-container">
          <Link to="/" className="logo-link">
            <img src="/logo192.png" alt="Logo" className="logo" />
          </Link>
          <span className="site-name">Amir Khatibzadeh</span>
        </div>
        <nav>
          <ul className="nav-links">
            <li><Link to="/">Home</Link></li>
            <li><Link to="/resume">Resume</Link></li>
          </ul>
        </nav>
      </header>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/resume" element={<Resume />} />
      </Routes>
    </div>
  );
}

export default App;