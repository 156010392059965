import React from 'react';
import './HomeSection.css'; // Add styles here

const HomeSection: React.FC = () => {
  return (
    <section className="home-section">
      <div className="content">
        <img src="/logo192.png" alt="Amir Khatibzadeh" />
        <h1>Amir Khatibzadeh</h1>
        <h2>Innovative Solutions, Driven by Expertise</h2>
        <p>
          I am a self-motivated, challenge-seeking engineering manager with a passion for software development, cloud migration and cloud integration.
          With over a two decades of experience, I lead teams in creating seamless, scalable solutions for modern businesses.
        </p>
      </div>
    </section>
  );
};

export default HomeSection;