import React, { useState, useEffect } from 'react';
import './BlogSection.css';

interface Project {
  title: string;
  company: string;
  date: string;
  description: string;
  videoUrl?: string; // Optional field for video URL
}

const BlogSection: React.FC = () => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [loading, setLoading] = useState<boolean>(true); // Add loading state

  useEffect(() => {
    // Simulate network request delay for loading state
    setTimeout(() => {
      fetch('/projects-data.json')
        .then(response => response.json())
        .then(data => {
          setProjects(data);
          setLoading(false); // Stop loading once data is fetched
        });
    }, 1000); // Simulate delay (optional)
  }, []);

  if (loading) {
    return (
      <div className="loading">
        <div className="spinner"></div>
        <p>Loading projects...</p>
      </div>
    );
  }

  return (
    <div className="blog-section">
      {projects.map((project, index) => (
        <div key={index} className="blog-post">
          <h2>{project.title}</h2>
          <p className="company">{project.company}</p>
          <p className="date">{project.date}</p>
          <p>{project.description}</p>
          {/* If video URL exists, embed YouTube video */}
          {project.videoUrl && (
            <div className="video-container">
              <iframe
                width="560"
                height="315"
                src={project.videoUrl.replace('youtu.be/', 'www.youtube.com/embed/')}
                title={project.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default BlogSection;
