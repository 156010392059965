import React from 'react';
import BlogSection from '../../components/BlogSection/BlogSection';
import './Resume.css'; // Add styles for the resume page

const Resume: React.FC = () => {
  return (
    <div className="resume-page">
      <h1>Projects</h1>
      <BlogSection />
    </div>
  );
};

export default Resume;
